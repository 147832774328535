import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const SecHeader = ({ Logo, dropdownItem, MenuInfo }) => {
  const [showModal, setShowModal] = useState(false);

  const handleStakeClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowModal(true); // Show the modal
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-white fixed-top" id="banner">
        <div className="container">
          {/* Brand */}
          <a className="navbar-brand" href="#"><span><img src={Logo} alt="logo" /></span></a>
          {/* Toggler/collapsibe Button */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false">
            <span className="navbar-toggler-icon" />
          </button>
          {/* Navbar links */}
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto"> 
              {MenuInfo && MenuInfo.map((item, key) => (
                <li className="nav-item" key={key}>
                  {item.nameLink === 'Stake' ? (
                    <a className="nav-link" href="#" onClick={handleStakeClick}>{item.nameLink}</a>
                  ) : (
                    <a className="nav-link" href={item.path}>{item.nameLink}</a>
                  )}
                </li>
              ))}
              <li className="lh-55px">
                <a href="#" className="btn login-btn ml-50" onClick={() => window.open('https://pump.fun', '_blank')}>Buy now</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Modal for Stake */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="stakeModalLabel" aria-hidden={!showModal}>
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ backgroundColor: '#004d00', color: 'white' }}>
            <div className="modal-header" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
            <div className="who-we-contant">
              <h4 className="fadeInUp" data-aos="fade-up">Coming Soon!</h4>
            </div>
              <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             
              <div className="who-we-contant">
              <p className="fadeInUp" data-aos="fade-up">We are excited to announce that staking features will be available in Phase 2. Stay tuned for updates!</p>
            </div>
            </div>
            <div className="modal-footer" style={{ borderTop: '1px solid rgba(255, 255, 255, 0.2)' }}>
            <div className="dream-btn-group" data-wow-delay="0.4s">
                <a onClick={() => setShowModal(false)} className="btn more-btn mr-3 addMarBtnLink" ><strong style={{color: "black"}}>Close</strong></a>
            </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecHeader;
