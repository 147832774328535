import React, { useState } from 'react';
import "./AboutUs.scss";
import { symbolImg } from '../../utils/allImgs';

const AboutUs = ({
  ClassSec = "about-us-area section-padding-100 banner-bg addMLInSmall mt-50",
  Computer1,
  Trade,
  titleUp,
  titleDown,
  textUp,
  textDown,
  link,
  link2,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const tokenAddress = "Not listed yet, deployment in progress..."; 

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tokenAddress)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  return (
    <section className={ClassSec} id="about" style={{ marginBottom: 30 }}>
      <div className="container">
        <div className="d-inline-block">
          <div className="contract-add align-items-center" onClick={copyToClipboard}>
            <i className={isCopied ? "fa fa-check" : "fa fa-copy"}></i>
            <h6 className="mr-3 mb-0" style={{ color: "white" }}>Token address : </h6>
            <span className="w-text" style={{ color: "white" }}>{tokenAddress}</span>
            <img src={symbolImg} className="symbol-img" width="100" alt="" />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
            <div className="welcome-meter">
              <img draggable="false" className="comp-img" src={Computer1} alt="" />
              <img draggable="false" className="supportImg floating-anim" src={Trade} alt="image" />
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up">
                <span className="gradient-text">{titleUp}</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up" style={{color: "white"}}>{titleDown}</h4>
              <p className="fadeInUp" data-aos="fade-up">{textUp}</p>
              <p className="fadeInUp" data-aos="fade-up">{textDown}</p>
              <a className="btn more-btn mt-15 fadeInUp" data-aos="fade-up" href="https://coinvote.cc/" target='_blank'>{link}</a>
              <a className="btn more-btn mt-15 fadeInUp" data-aos="fade-up" href="https://coinhunt.cc/" target='_blank' style={{ marginLeft: 10 }}>{link2}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
