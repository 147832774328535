import { useEffect } from "react";
import { addRemoveClassBody } from '../../utils'

import {
  HowToStartInfo,
  OurTeamInfo
} from '../../data/data-containers/HomeDemo3/data-HomeDemo3.js';

import dataTokenInfoUp from '../../data/data-containers/HomeDemo3/data-TokenInfoUp.json';
import dataTokenInfoDown from '../../data/data-containers/HomeDemo3/data-TokenInfoDown.json';
import RoadmapInfo from '../../data/data-containers/HomeDemo3/data-RoadmapInfo.json';
import FaqInfo from '../../data/data-containers/HomeDemo3/data-FaqInfo.json';
import dataContact from "../../data/data-containers/HomeDemo3/data-contact.json"
import IcoName from '../../data/data-layout/Footer/data-IcoName.json'
import TextFooter from '../../data/data-layout/Footer/data-TextFooter.json'


import {
  HomeDemo3Computer1,
  HomeDemo3Trade,
  HomeDemo3Payment,
  HomeDemo3Whitepaper1,
  HomeDemo3Trade1,
  Hero3Imag,
  HomeDemo3Dist,
  HomeDemo3Faq,
  FooterPattern,
  FooterLogo,
} from '../../utils/allImgs'

import Header from "../../layouts/Header"
import Footer from '../../layouts/Footer'

import SecHeroSection from '../../components/HeroSection'
import AboutUs from '../../components/AboutUs'
import SecToken from '../../components/SecToken'
import OurICO from '../../components/OurICO'
import Roadmap from '../../components/Roadmap'
import TokenInfo from '../../components/TokenInfo'
import HowToStart from '../../components/HowToStart'
import Faq from '../../components/Faq'
import OurTeam from '../../components/OurTeam'
import Subscribe from '../../components/Subscribe'

const HomeDemo3 = () => {

  useEffect(() => {
    addRemoveClassBody('darker')
  }, [])

  return (
    <div>
      <Header Title="Kameleon - Meme" />
      <SecHeroSection
        ClassSec="hero-section banner-bg section-padding mb-100"
        ColClass="col-12 col-lg-6 col-md-12"
        specialHead="Catch The Future and Hold More Memecoins"
        textUp="$KAM - The Future of Meme Coins"
        textDown="Welcome to the vibrant world of Kameleon, where humor meets the hype of crypto! Our mission is to create a playful and engaging meme coin that captures the essence of fun while you join us on this colorful journey. Embrace the laughter and excitement as we blend creativity with community!"
        link1="PUMP.FUN"
        link2="Twitter"
        link3="Telegram"
        wrapperClass="hedo-wrapper floating-anim mt-s"
        img={Hero3Imag}
      />
      <AboutUs
        ClassSec="about-us-area section-padding-100-0 clearfix"
        Computer1={HomeDemo3Computer1}
        Trade={HomeDemo3Trade}
        titleUp="About Kameleon"
        titleDown="Decentralized Meme Coin with a Twist"
        textUp="Welcome to Kameleon, where laughter and creativity collide in the world of cryptocurrency! Our platform is not just another coin, it's a movement driven by humor, community, and the vibrant spirit of meme culture."
        textDown="Why Invest in KAM?
Join us in celebrating the joy of crypto with KAM! This is more than just a coin, it's an opportunity to be part of a fun and engaging community. As we navigate the exciting landscape of meme coins, Kameleon aims to stand out with its unique charm and playful approach."
        textDown2="Investing in KAM means embracing a project that thrives on creativity and hope. Whether you're a seasoned trader or new to the crypto space, Kameleon invites you to take part in our journey. Let’s build a colorful future together, where every transaction brings a smile!"
        link="VOTE on CoinVote"
        link2="VOTE on CointHunt"
      />
      <div className="clearfix" />
      <HowToStart
        data={HowToStartInfo}
      />
      <TokenInfo
        img={HomeDemo3Dist}
        dataUp={dataTokenInfoUp}
        dataDown={dataTokenInfoDown}
      />
      <Roadmap
        data={RoadmapInfo}
        ClassSpanTitle="roadmap section-padding-0-0"
      />
      <OurICO
        Whitepaper1={HomeDemo3Whitepaper1}
        Trade1={HomeDemo3Trade1}
        title="Kameleon Whitepaper"
        textUp="View the Kameleon Whitepaper"
        textDown="Explore the exciting journey of Kameleon directly on our website. Discover our vision, innovative tokenomics, and community-driven approach designed to make investing in meme coins both fun and rewarding. Join us as we pave the way for a vibrant and engaging crypto community!"
        link="View Whitepaper"
        link2="Download"
      />
{/*   
      <Faq
        ClassSpanTitle="faq-timeline-area section-padding-100-85"
        img={HomeDemo3Faq}
        data={FaqInfo}
      /> 
      <Subscribe /> */}
      <Footer
        FooterPattern={FooterPattern}
        FooterLogo={FooterLogo}
        text="At Kameleon, we are dedicated to transforming the crypto landscape with innovative solutions. Our commitment to excellence drives us to deliver unique experiences and empower our community to thrive. Join us on this exciting journey!"
        IcoName={IcoName}
        TextFooter={TextFooter}
        dataContact={dataContact}
      />
    </div>
  );
};

export default HomeDemo3