import SectionHeading from "../SectionHeading"
import "./Roadmap.scss"

const Roadmap = ({data , ClassSpanTitle}) => {

    return (

      <section className={`${ClassSpanTitle} addMLInSmall`} id="roadmap">
        <SectionHeading
          ClassSpanTitle="gradient-text blue"
          titleUp="Kameleon Roadmap"
          titleDown="Our Visionary Roadmap"
          text="Join us on our exciting journey as we unveil the future of Kameleon! Each phase is designed to engage our community and drive the project forward, ensuring that every holder feels a part of this vibrant movement."
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-timeline">
                {data && data.map((item , key) => (
                  <div className="timeline" key={key}>
                    <div className="icon" />
                    <div className="date-content">
                      <div className="date-outer"> <span className="date"> <span className="month">{item.month}</span> <span className="year">{item.year}</span> </span>
                      </div>
                    </div>
                    <div className="timeline-content">
                      <h5 className="title" style={{color: "white"}}>{item.title}</h5>
                      <p className="description text-light-gray"> {item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );

}

export default Roadmap