import "./Wellcome.scss"

const SecHeroSection = ({
      ClassSec="",
      ColClass="",
      specialHead="",
      textUp="",
      textDown="",
      link1="",
      link2="",
      link3="",
      img="",
      wrapperClass=""

    }) => {

  return (
    <section className={ClassSec} id="home">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center" style={{marginBottom: 20}}>
            {/* Welcome Content */}
            <div className={ColClass}>
              <div className="welcome-content">
                <div className="promo-section">
                  <h3 className="special-head">{specialHead}</h3>
                </div>
                <h1 data-wow-delay="0.2s" style={{color: "white"}}>{textUp}</h1>
                <p className="w-text">{textDown}</p>
                <div className="dream-btn-group" data-wow-delay="0.4s">
                  <a href="#" target="_blank" className="btn more-btn mr-3 addMarBtnLink">{link1}</a>
                  <a href="https://x.com/KameleonMeme" target="_blank" className="btn more-btn">{link2}</a>
                  <a href="https://telegram.org/" target="_blank" className="btn more-btn" style={{marginLeft: 15}}>{link3}</a>
                </div>
              </div>
            </div>
              <div className="col-lg-6">
                  <div className={wrapperClass}>
                    <img draggable="false" src={img} alt="" />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecHeroSection;